.heading {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleNav {
    font: var(--font-heading);
}

.pageTitle {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 15px;
}

.sectionTitle {
    font-size: 18px;
    margin-bottom: 10px;
}

.row1 {
    display: flex;
    gap: 6%;
    margin-bottom: var(--gap-large);
}

.sectionSubtitle {
    font: (--font-paragraph);
    color: rgba(var(--base-ntrl-400));
}

.row2,
.row3 {
    display: flex;
    margin-bottom: var(--gap-large);
    margin-top: 10px;
    gap: 6%;
}

.propertiesTitle {
    display: flex;
    gap: var(--gap-large);
    height: 50px;
    align-items: center;
}

.propertiesTitle input {
    width: 150px;
    padding: 12px 16px;
    border-radius: var(--border-radius-default);
    border: #B1B1B1 solid 2px;
}

.propertiesInputs {
    display: flex;
    gap: 9%;
    width: 65%;
    margin-top: 10px;
}

.propertiesGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-default);
    width: 50%;
}

.inputProperties {
    display: flex;
    flex-direction: column;
    gap: var(--gap-default);
}

.inputProperties input {
    height: 50px;
    padding: 12px;
    border-radius: var(--border-radius-default);
    border: #B1B1B1 solid 2px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    height: 80px;
    align-items: center;
    gap: var(--gap-default);
}

.contactInput {
    width: 100%;
    display: flex;
    gap: var(--gap-default);
    align-items: center;
    position: relative;

}

.contactInput>:first-child {
    width: 30%;
    display: flex;
    margin-bottom: 1.3rem;
}

.error {
    color: red;
    font-size: 9px;
}

.errorNumberContainer {
    display: flex;
    position: absolute;
    top: 85%;
}

.errorNumber {
    color: red;
    font-size: 9px;
}

.inputItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--gap-default);
}

@media only screen and (max-width: 900px) {

    .pageTitle {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .heading {
        padding-top: 1rem;
        margin-bottom: 2rem;
    }

    main {
        overflow: hidden;
    }

    .settingsForm {
        max-width: 75vw;
        margin-bottom: 100px;
    }

    .row1 {
        flex-wrap: wrap;
        gap: var(--gap-default);
        max-width: 100%;
    }

    .inputGroup {
        width: 100%;
    }

    .inputGroup label {
        margin-top: 5px;
    }

    .row2,
    .row3 {
        flex-wrap: wrap;
        gap: var(--gap-default);
    }

    .propertiesTitle {
        justify-content: space-between;
    }

    .propertiesInputs {
        flex-direction: column;
        width: 100%;
    }

    .propertiesGroup {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 440px) {

    .heading {
        max-width: 60vw;
    }

    .settingsForm {
        max-width: 65vw;
    }

    .row1,
    .row2 {
        flex-direction: column;
    }

    .propertiesInputs {
        margin-top: 60px;
    }

    .propertiesTitle {
        flex-direction: column;
    }

    .propertiesTitle input {
        width: 100%;
    }

    .buttons {
        flex-direction: column;
        margin-top: 10px;
    }

    .buttons> :first-child {
        order: 2;
    }

    .buttons> :last-child {
        order: 1;
    }
}