.footer {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links {
    display: flex;
    gap: var(--gap-large);
    font-size: var(--paragraph-small-fx);
}

.links > * {
    color: rgba(var(--base-shade-max), 1); 
}

@media only screen and (max-width: 900px) {
    .footer {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer >:first-child {
        margin-bottom: 2rem;
    }
    
    .links {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-large);
        font-size: var(--paragraph-small-fx);
        justify-content: center;
        margin-left: -2rem;
        margin-bottom: 2rem;
    }
    
    .links > * {
        color: rgba(var(--base-shade-max), 1); 
    }
}

@media only screen and (max-width: 720px) {
    
    .footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        margin-left: -1rem;
    }
    
    .links {
        display: flex;
        flex-direction: column;
        gap: var(--gap-large);
        font-size: var(--paragraph-small-fx);
        justify-content: center;
        margin-left: 0px;
        margin-bottom: 6rem;
        text-align: center;
        width: 100%;
    }
    
    .links > * {
        color: rgba(var(--base-shade-max), 1); 
    }  
}