.heading {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleNav {
    font:var(--font-heading)
}

.mainContainer {
    min-height: 90vh;
    padding: 10px 0px;
}

.container {
    display:flex; 
    margin-top:3rem;         
    flex-wrap: wrap
}

.firstCardGroup {
    position: relative;
    background-color: "#F5F5F5";
    border: #D0D0D0 solid 2px;
    border-radius: 5px;
    width: 350px;
    height: 500px;
    margin-right: 2%;
    padding: 0 20px;
}

.secondCardGroup {
    position: relative;
    background-color: #ECF3EC;
    border: #C9E9C9 solid 2px;
    border-radius: 5px;
    width: 350px;
    height: 500px;
    margin-right: 2%;
    padding: 0 20px;
}

.titleGroup {
    text-align: center;
    margin: 3rem 0 1rem 0;
    font:var(--font-heading)
}

.paragraphGroup {
    text-align: center;
    margin-bottom: 2rem;
    font: var(--font-paragraph)
}

.checkGroup {
    display: flex;
    gap: 10px;
    margin-bottom: 10px
}

.divIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconGroup {
    color:#46625F;
}

.textIcon {
    font: var(--font-paragraph)
}

.containerButton {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
}

.containerButton > :first-child {
    margin: auto;
}

.containerForm{
    margin: 2rem 0 0 0;
}

.textLabel {
    font-weight: bold;
    margin-left: 10px;
    font: var(--font-paragraph);
    font-weight: bold
}

.paragraphForm {
    margin-top: 5px;
    margin-bottom: 2rem;
    font: var(--font-paragraph);
}

.divButtonForm {
    margin: 2rem 0 1rem 0;
}

@media only screen and (max-width: 900px) {
    .container {
        margin: 10px 0 0 0 ;
    }

    .heading {
        padding-top: 1rem;
        margin-bottom: 2rem;
    }

    .mainContainer{
        margin-bottom: 4rem;
    }

    .firstCardGroup {
        margin-bottom: 20px;
    }

    .firstCardGroup, .secondCardGroup{
        width: 300px;
        height: 450px;
    }

    .titleGroup {
        margin: 3rem 0 1rem 0;
        font:var(--font-heading)
    }

    .containerForm{
        margin: 2rem 0 0 0;
    }

    .divButtonForm {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 440px) {
    .container {
        margin: 10px 0 0 0 ;
    }

    .firstCardGroup {
        margin-bottom: 20px;
    }

    .firstCardGroup, .secondCardGroup{
        width: 250px;
        height: 450px;
    }

    .titleGroup {
        margin: 3rem 0 1rem 0;
        font:var(--font-heading)
    }

    .containerForm{
        margin: 2rem 0 0 0;
    }

    .divButtonForm {
        margin-bottom: 40px;
    }
}
