.button {
    min-width: 180px;
    width: min-content;
    height: 44px;
    height: min-content;
    max-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(var(--gap-default)*2);
    cursor: pointer;
    border-radius: var(--border-radius-default);
    transition: .2s ease-in-out;
}

.buttonLeft {
    justify-content: start !important;
}

.button:hover {
    filter: brightness(.9);
}

.button:active {
    filter: brightness(.7);
}

.button.noPadding { padding: 0; min-width: 0; }
.button.padding { padding: var(--padding-items); }

.button.right { flex-direction: row-reverse; }
.button.left { flex-direction: row; }

.button.filled-primary {
    background-color: rgba(var(--base-primary), 1);
    color: rgba(var(--base-shade-min), 1);
    border: none;
}
.button.empty-primary {
    --_main-color: rgba(var(--base-primary), 1);
    background-color: transparent;
    color: rgba(var(--base-primary), 1);
    border: 2px solid rgba(var(--base-primary), 1);
}
.button.transparent-primary {
    background-color: transparent;
    color: rgba(var(--base-primary), 1);
    border: none
}

.button.filled-secondary {
    background-color: rgba(var(--base-secondary), 1);
    color: rgba(var(--base-shade-max), 1);
    border: none;
}
.button.empty-secondary {
    --_main-color: rgba(var(--base-secondary), 1);
    background-color: transparent;
    color: rgba(var(--base-secondary), 1);
    border: 2px solid rgba(var(--base-secondary), 1);
}
.button.transparent-secondary {
    background-color: transparent;
    color: rgba(var(--base-secondary), 1);
    border: none;
}

.button.filled-accent {
    background-color: rgba(var(--base-accent), 1);
    color: rgba(var(--base-shade-min), 1);
    border: none;
}
.button.empty-accent {
    --_main-color: rgba(var(--base-accent), 1);
    background-color: transparent;
    color: rgba(var(--base-accent), 1);
    border: 2px solid rgba(var(--base-accent), 1);
}
.button.transparent-accent {
    background-color: transparent;
    color: rgba(var(--base-accent), 1);
    border: none;
}
.button.filled-gray {
    background-color: rgba(var(--base-ntrl-400), 1);
    color: rgba(var(--base-shade-max), 1);
    border: none;
}
.button.empty-gray {
    --_main-color: rgba(var(--base-ntrl-400), 1);
    background-color: transparent;
    color: rgba(var(--base-ntrl-400), 1);
    border: 2px solid rgba(var(--base-ntrl-400), 1);
}
.button.transparent-gray {
    background-color: transparent;
    color: rgba(var(--base-ntrl-400), 1);
    border: none;
}

.button.filled-error {
    background-color: rgba(var(--base-error), 1);
    color: rgba(var(--base-shade-min), 1);
    border: none;
}

.button.empty-error {
    --_main-color: rgba(var(--base-error), 1);
    background-color: transparent;
    color: rgba(var(--base-error), 1);
    border: 2px solid rgba(var(--base-error), 1);
}
.button.transparent-error {
    background-color: transparent;
    color: rgba(var(--base-error), 1);
    border: none;
}

.button.neutral {
    background: none;
    padding: 0;
    border: none;
    min-width: 0;
    color: rgba(var(--base-shade-max), 1);
}

.button.neutral span,
.button.filled-gray span, 
.button.empty-gray span, 
.button.transparent-gray span {
    font-weight: bold;
}

.button span, .icon {
    white-space: nowrap;
    font: var(--font-paragraph)
}

*[class*=empty]:hover {
    background-color: var(--_main-color);
}

*[class*=empty]:hover span {
    filter: invert(1) grayscale(1) contrast(9);
}

.button.disabled {
    opacity: 0.6; 
    cursor: not-allowed; 
    pointer-events: none;
}

@media only screen and (max-width: 440px) {
    .button{
        min-width: 90px;
    }
}