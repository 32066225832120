/* Defaults */
.sct {
    width: 100%;
    --_sct-height: 100vh;
    min-height: var(--_sct-height);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(200px, var(--_sct-height));
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.sct__alternative {
    width: 100%;
    --_sct-height: 100vh;
    min-height: var(--_sct-height);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(200px, var(--_sct-height));
    margin-bottom: 3rem;
    margin-top: 3rem;
}

.sct__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-spacing);
    gap: var(--gap-default);
}

.sct__content h1 { font: var(--font-display); }
.sct__content > *:is(h2, h3) { font: var(--font-heading); }
.sct__content > p { font: var(--font-paragraph); } 

/* Hero section */
.sctHero { --_sct-height: auto; margin: 5rem auto;}

.sctHero__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content auto;
    align-items: center;
}

.sctHero__illustration {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.sctHero__illustration svg {
    position: absolute;
    width: 90%;
}

#sctHero__illustration__background {
    rotate: -180deg;
}

#sctHero__illustration__background path {
    fill: rgba(var(--base-primary), 1);
}

#sctHero__illustration__foreground__image {
    filter: brightness(.6);
}

/* About section */
.sctAbout {
    --_sct-height: 60vh;
    background-color: rgba(var(--base-shade-min), 1);
    border-radius: var(--border-radius-default);
    box-shadow: 0 4px 16px rgba(var(--base-shade-max), .15);
}

.sctAbout__illustration {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sctAbout__illustration__image {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 50vh;
    height: 59%;
    min-width: 50%;
    background-image: var(--_url);
    background-size: contain;
    background-repeat: no-repeat;
    /* background-color: rgba(var(--base-ntrl-900), 1); */
    border-radius: var(--border-radius-default);
    box-shadow: 0 0 8px rgba(var(--base-shade-max), .15);
    transform-style: preserve-3d;
}

.sctAbout__illustration__image::before {
    content: "";
    position: absolute;
    top: 53%;
    left: 53%;
    transform:
        translate(-50%, -50%)
        translateZ(-1px);
    width: 100%;
    height: 100%;
    border: 2px solid rgba(var(--base-primary), 1);
    border-radius: var(--border-radius-default);
}

.sctAbout__illustration__image button {
    height: 100%;
    width: 50px;
    border: none;
    text-align: center;
    color: rgba(var(--base-shade-max), 1);
    font-size: var(--paragraph-large-fx);
    cursor: pointer;
}

.sctAbout__illustration__image button:first-child {
    background: radial-gradient(90deg, rgba(var(--base-shade-min), .5) 0%, transparent 0%);
}

.sctAbout__illustration__image button:last-child {
    background: radial-gradient(90deg, transparent 100%, rgba(var(--base-shade-min), .5) 100%);
}

.sctAbout__illustration__image button:first-child:hover {
    background: radial-gradient(90deg, rgba(var(--base-shade-min), .5) 0%, transparent 100%);
}

.sctAbout__illustration__image button:last-child:hover {
    background: radial-gradient(90deg, transparent 0%, rgba(var(--base-shade-min), .5) 100%);
}

/* Features section */
.sctFeatures {
    min-height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(var(--gap-default)*3);
}

.sctFeatures__features {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.sctFeatures__feature {
    max-width: 450px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px;
    gap: var(--gap-default);
}

.sctFeatures__feature p {
    display: flex;
    align-items: center;
    font: var(--font-paragraph);
}

.svgStyle{
    width: 100%;
    height: 100%;
}

.imageRadar {
    width: 100%;
}

/* Areas section */
.sctArea {
    --_sct-height: 70vh;
}

.sctArea__content {
    padding-right: calc(var(--gap-default) * 3);
}

.sctArea__illustration {
    max-width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-default);
    overflow: hidden;
}

.sctArea__illustration::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* box-shadow:
        inset -8px 0 32px 18px rgba(var(--base-ntrl-100), 1),
        inset 8px 0 32px 18px rgba(var(--base-ntrl-100), 1); */
    pointer-events: none;
    z-index: 2;
}

.sctArea__illustration__slide {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: var(--_url);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(var(--base-ntrl-900), 1);
    border-radius: var(--border-radius-default);
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.04);
}

.sctArea__illustration button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 50px;
    border: none;
    text-align: center;
    color: rgba(var(--base-shade-min), 1);
    font-size: var(--paragraph-large-fx);
    cursor: pointer;
    z-index: 3;
}

.sctArea__illustration button:nth-child(1) {
    left: 0;
    background: radial-gradient(at left, rgba(var(--base-shade-min), .2) 0%, transparent 0%);
}

.sctArea__illustration button:nth-child(2) {
    right: 0;
    background: radial-gradient(at right, rgba(var(--base-shade-min), .2) 0%, transparent 0%);
}

.sctArea__illustration button:nth-child(1):hover {
    background: radial-gradient(at left, rgba(var(--base-shade-min), .2) 0%, transparent 50%);
}

.sctArea__illustration button:nth-child(2):hover {
    background: radial-gradient(at right, rgba(var(--base-shade-min), .2) 0%, transparent 50%);
}

[data-previous="true"] {
    left: -20%;
    width: 350px;
    height: 50%;
    filter: grayscale(.6);
    z-index: 0;
}

[data-current="true"] {
    display: flex;
    justify-content: space-between;
    left: 50%;
    width: 450px;
    height: 70%;
    z-index: 1;
}

[data-next="true"] {
    left: 120%;
    width: 350px;
    height: 50%;
    filter: grayscale(.6);
    z-index: 0;
}

/* Business section */
.sctBusiness {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap-default)*5);
}

.sctBusiness hr {
    color: rgba(var(--base-accent), 1)
}

.sctBusiness__title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(var(--gap-default)*3);
    margin-top: 3rem;
}

.sctBusiness__title hr {
    width: 100%;
    height: 1px;
}

.sctBusiness__title h2 {
    font: var(--font-heading);
    white-space: nowrap;
}

.sctBusiness__title h2 span {
    color: rgba(var(--base-accent), 1);
}

.sctBusiness .sct { --_sct-height: 50vh; }

.sctBusiness__illustration {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sctBusiness__illustration >:last-child {
    width: 100% !important;
    justify-content: center;
}

.sctBusiness__illustration__card__top {
    display: flex;
    gap: 15px;
}

.sctBusiness__illustration__card {
    display: flex;
    align-items: center;
    padding: var(--padding-spacing);
    border-radius: var(--border-radius-default);
    border:1px solid rgba(var(--base-accent), 1);
    width: 50%;
    height: 160px;
}

.sctBusiness__illustration__card span {
    display: block;
    min-width: 100px;
    min-height: 100px;
    height: 100px;
}

.sctBusiness__illustration__card p {
    font-size: var(--paragraph-large-fx);
    color: rgba(var(--base-accent), 1);
}

/* Spotsat section */
.sctSpotsat {
    --_sct-height: 40vh;
}

.sctSpotsat__illustration {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Register modal */
dialog button {
    min-width: 80% !important; 
}

/* dialog *:has(> input) {
    max-width: 80% !important;
} */

dialog:has(input) > p {
    font: var(--font-paragraph);
}

.modalOpenLogi, .modalRegister {
    text-align: center;
    font-size: var(--font-paragraph);
}

.paragraphPadding {
    padding: var(--padding-spacing);
}

.paragraphPaddingTop {
    padding-top:1rem
}

.paragraphPaddingBottom {
    padding-bottom:1rem
}

.modalParagraph {
    width: 80% !important;
    text-align: right;
}

.paragraphCenter {
    text-align: center;
}

.paragraphLeft {
    text-align: left;
}

.paragraphError {
    width: 80% !important;
    text-align: left;
    padding-bottom:1rem
}

.modalUnderline {
    text-decoration: underline;
    cursor: pointer;
}

.sctAreaMargin {
    padding: 3rem 0;
}

.marginVertical {
    margin: 1.2rem 0;
}

.marginRight{
    margin-right: 3rem;
}

.divBanner .bannerImage {
    display: none;
}

.divBanner .webBanner {
    display: block;
    width: 100%;
    margin: 0 auto;
}


@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .sctAbout {
        --_sct-height: 50vh;
    }

/* Banner Imagem */

.divBanner .webBanner {
    display: block;
    width: 100%;
    margin: 0 auto;
}

    .sctAbout__illustration__image{
        width: 40vh;
        height: 57%;
    }

    .sctBusiness .sct {
        --_sct-height: auto;
    }

    .sctAreaMargin{
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
}
@media only screen and (min-width: 721px) and (max-width: 900px) {
    /* Defaults */
    .sct {
        margin-bottom: 2rem;
        margin-top: 0;
    }

    .container {
        margin-left: 0;
    }

    .sctHero__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content auto;
    }

    .sctHero__content svg {
        margin-left: 1rem;
        margin-top: 1rem;
    }

    .sctHero__illustration {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* About section */
    .sctAbout {
        --_sct-height: 40vh;
        background-color: rgba(var(--base-shade-min), 1);
        border-radius: var(--border-radius-default);
        box-shadow: 0 4px 16px rgba(var(--base-shade-max), .15);
        display: grid;
        align-items: center;
    }

    .sctAbout__illustration {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30vh;
    }

    .sctAbout__illustration__image {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 31vh;
        height: 73%;
        min-width: 50%;
        background-image: var(--_url);
        background-size: contain;
        background-repeat: no-repeat;
        /* background-color: rgba(var(--base-ntrl-900), 1); */
        border-radius: var(--border-radius-default);
        box-shadow: 0 0 8px rgba(var(--base-shade-max), .15);
        transform-style: preserve-3d;
    }

    .sctAbout__illustration__image::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform:
            translate(-50%, -50%)
            translateZ(-1px);
        width: 105%;
        height: 105%;
        border: 2px solid rgba(var(--base-primary), 1);
        border-radius: var(--border-radius-default);
    }

    .sctAbout__illustration__image button {
        height: 100%;
        width: 50px;
        border: none;
        text-align: center;
        color: rgba(var(--base-shade-max), 1);
        font-size: var(--paragraph-large-fx);
        cursor: pointer;
    }

    .sctAbout__content {
        margin-top: -2rem;
        padding-inline: 2rem;
    }

    .sctAbout__content h2 {
        text-align: left !important;
    }

    /* Features section */
    .sctFeatures {
        min-height: 24vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: calc(var(--gap-default)*3);
        margin: 0rem 0px 1rem;
    }

    .sctFeatures__features {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .svgStyle{
        width: 100%;
        height: 100%;
    }
    
    .imageRadar {
        width: 120%;
    }
    
    .sctFeatures__feature {
        max-width: 250px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 150px;
        gap: var(--gap-default);
        align-items: center;
        gap: 30px;
    }

    .sctFeatures__feature p {
        display: flex;
        align-items: center;
        font: var(--font-paragraph);
    }

    /* Areas section */
    .sctArea {
        --_sct-height: auto;
        margin-bottom: 0;
    }

    div .sctArea__content> :last-child {
        margin: 0 0 1rem;
    }

    /* Business section */
    .sctBusiness {
        display: flex;
        flex-direction: column;
        gap: calc(var(--gap-default)*5);
    }

    .sctBusiness hr {
        color: rgba(var(--base-accent), 1)
    }

    .sctBusiness__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: calc(var(--gap-default)*3);
    }

    .sctBusiness__title hr {
        width: 100%;
        height: 1px;
    }

    .sctBusiness__title h2 {
        font: var(--font-heading);
        white-space: nowrap;
    }

    .sctBusiness__title h2 span {
        color: rgba(var(--base-accent), 1);
    }

    .sctBusiness .sct { --_sct-height: 60vh; }

    .sctBusiness__illustration {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr; */
        gap: 16px;
    }

    .sctBusiness__illustration__card {
        display: flex;
        align-items: center;
        padding: var(--padding-spacing);
        border-radius: var(--border-radius-default);
        border:1px solid rgba(var(--base-accent), 1);
        width: 100%;
    }

    .sctBusiness__illustration__card span {
        display: block;
        min-width: 100px;
        min-height: 100px;
        height: 100px;
    }

    .sctBusiness__illustration__card p {
        font-size: var(--paragraph-large-fx);
    }

    .sctBusiness__content {
        margin-top: -3rem;
        margin-bottom: 1.5rem;
    }

    /* Spotsat section */
    .sctSpotsat {
        --_sct-height: 20vh;
    }

    .sctSpotsat__illustration {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imageSpotsat {
        width: 80%;
    }

    /* Register modal */
    dialog button {
        min-width: 80% !important; 
    }

    dialog *:has(> input) {
        max-width: 80% !important;
    }

    dialog:has(input) > p {
        font: var(--font-paragraph);
    }

    .modalOpenLogi, .modalRegister {
        text-align: center;
        font-size: var(--font-paragraph);
    }

    .paragraphPadding {
        padding: var(--padding-spacing);
    }

    .paragraphPaddingTop {
        padding-top:1rem
    }

    .paragraphPaddingBottom {
        padding-bottom:1rem
    }

    .modalParagraph {
        width: 80% !important;
        text-align: right;
    }

    .paragraphCenter {
        text-align: center;
    }

    .paragraphLeft {
        text-align: left;
    }

    .paragraphError {
        width: 80% !important;
        text-align: left;
        padding-bottom:1rem
    }

    .modalUnderline {
        text-decoration: underline;
        cursor: pointer;
    }

    .sctAreaMargin {
        padding: 3rem 0;
        gap: var(--gap-default);
        display: flex;
        flex-direction: column;
    }

    .marginVertical {
        margin: 1.2rem 0;
    }

    .marginRight{
        margin-right: 3rem;
    }

    /* Banner Imagem */
    .divBanner .webBanner {
        display: none;
    }
    
    .divBanner .tabletBanner {
        display: block;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
    }

}
@media only screen and (min-width: 441px) and (max-width: 720px) {
    /* Defaults */
    .sct {
        margin-left: -1rem;
        margin-top: 0;
        margin-bottom: 0rem !important; 
    }

    .sctHero{
        margin: 3rem auto;
    }

    .sctHero__content {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: min-content auto;
        padding: 0;
    }

    .sctHero__content .sct__content{
        padding: 0;
    }

    .sctHero__illustration {
        position: relative;
        display: none;
        justify-content: center;
        align-items: center;
    }

    /* About section */
    .sctAbout {
        --_sct-height: 31vh;
        min-height: 470px;
        background-color: rgba(var(--base-shade-min), 1);
        border-radius: var(--border-radius-default);
        box-shadow: 0 4px 16px rgba(var(--base-shade-max), .15);
        display: grid;
        grid-template-columns: 100%;
    }

    .sctAbout__content h2 {
        text-align: left !important;
    }

    .sctAbout__illustration {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 31vh;
    }

    .sctAbout__illustration__image {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 31vh;
        height: 72%;
        min-width: 50%;
        background-image: var(--_url);
        background-size: contain;
        background-repeat: no-repeat;
        /* background-color: rgba(var(--base-ntrl-900), 1); */
        border-radius: var(--border-radius-default);
        box-shadow: 0 0 8px rgba(var(--base-shade-max), .15);
        transform-style: preserve-3d;
    }

    .sctAbout__illustration__image::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform:
            translate(-50%, -50%)
            translateZ(-1px);
        width: 105%;
        height: 105%;
        border: 2px solid rgba(var(--base-primary), 1);
        border-radius: var(--border-radius-default);
    }

    .sctAbout__illustration__image button {
        height: 100%;
        width: 50px;
        border: none;
        text-align: center;
        color: rgba(var(--base-shade-max), 1);
        font-size: var(--paragraph-large-fx);
        cursor: pointer;
    }

    /* Features section */
    .sctFeatures {
        min-height: 35vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: calc(var(--gap-default)*3);
        margin: 2rem 0 0;
        
    }

    .sctFeatures__features {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .svgStyle{
        width: 100%;
        height: 100%;
    }
    
    .sctFeatures__feature {
        max-width: 250px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 150px;
        gap: var(--gap-default);
        align-items: center;
        gap: 30px;
    }

    .sctFeatures__feature p {
        display: flex;
        align-items: center;
        font: var(--font-paragraph);
    }

    /* Areas section */
    .sctArea {
        --_sct-height: 90vh;
    }

    .sctAreaContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 400px 1fr;
    }

    .sctAreaContainer > :first-child {
        order:1
    }

    div .sctArea__content> :last-child {
        margin: 0 0 1rem;
    }

    /* Business section */
    .sctBusiness {
        display: flex;
        flex-direction: column;
        gap: calc(var(--gap-default)*5);
    }

    .sctBusiness__content {
        width: 90%;
        margin-top:0rem;
        margin-bottom: 2rem;
    }

    .sctBusiness hr {
        color: rgba(var(--base-accent), 1);
    }

    .sctBusiness__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: calc(var(--gap-default)*3);
        margin-top: 2rem;
        margin-left: -2rem;
    }

    .sctBusiness__title hr {
        width: 100%;
        height: 1px;
    }

    .sctBusiness__title h2 {
        font: var(--font-heading);
        white-space: nowrap;
    }

    .sctBusiness__title h2 span {
        color: rgba(var(--base-accent), 1);
    }

    .sctBusiness .sct { --_sct-height: 60vh; }

    .sctBusiness__illustration {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr; */
        gap: 16px;
    }

    .sctBusiness__illustration__card__top {
        flex-direction: column;
    }

    .sctBusiness__illustration__card {
        display: flex;
        flex-direction: inherit;
        align-items: center;
        padding: var(--padding-spacing);
        border-radius: var(--border-radius-default);
        border:1px solid rgba(var(--base-accent), 1);
        width: 100%;
    }

    .sctBusiness__illustration__card span {
        display: block;
        min-width: 100px;
        min-height: 100px;
        height: 100px;
    }

    .sctBusiness__illustration__card p {
        font-size: var(--paragraph-large-fx);
    }

    /* Spotsat section */
    .sctSpotsat {
        --_sct-height: 40vh;
        display: flex;
        flex-direction: column;
    }

    .sctSpotsat >:first-child {
        order: 2;
    }

    .sctSpotsat__illustration {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imageSpotsat {
        margin: 2rem 0 6rem;
        width: 80%;
    }

    /* Register modal */
    dialog button {
        min-width: 80% !important; 
    }

    dialog *:has(> input) {
        max-width: 80% !important;
    }

    dialog:has(input) > p {
        font: var(--font-paragraph);
    }

    .modalOpenLogi, .modalRegister {
        text-align: center;
        font-size: var(--font-paragraph);
    }

    .paragraphPadding {
        padding: var(--padding-spacing);
    }

    .paragraphPaddingTop {
        padding-top:1rem
    }

    .paragraphPaddingBottom {
        padding-bottom:1rem
    }

    .modalParagraph {
        width: 80% !important;
        text-align: right;
    }

    .paragraphCenter {
        text-align: center;
    }

    .paragraphLeft {
        text-align: left;
    }

    .paragraphError {
        width: 80% !important;
        text-align: left;
        padding-bottom:1rem
    }

    .modalUnderline {
        text-decoration: underline;
        cursor: pointer;
    }

    .sctAreaMargin {
        padding: 0rem 0;
        gap: var(--gap-default);
        display: flex;
        flex-direction: column;
    }

    .marginVertical {
        margin: 1.2rem 0;
    }

    .marginRight{
        margin-right: 3rem;
    }

    /* Banner Imagem */
    .divBanner .webBanner {
        display: none;
    }
    
    .divBanner .tabletBanner {
        display: block;
        width: 100%;
        margin: 0 auto;
    }

}
@media only screen and (max-width: 440px) {
    /* Defaults */
    .sct {
        margin-left: -1rem;
        margin-bottom: 0rem !important; 
        margin-top: 0;
    }

    .sctHero {
        margin: 2rem auto;
    }

    .sctHero__content {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: min-content auto;
        margin-bottom: 2rem;
    }

    .sctHero__content .sct__content{
        padding: 0;
    }

    .sctHero__illustration {
        position: relative;
        display: none;
        justify-content: center;
        align-items: center;
    }

    /* About section */
    .sctAbout {
        --_sct-height: 31vh;
        min-height: auto;
        background-color: rgba(var(--base-shade-min), 1);
        border-radius: var(--border-radius-default);
        box-shadow: 0 4px 16px rgba(var(--base-shade-max), .15);
        display: grid;
        grid-template-columns: 100%;
    }

    .sctAbout__illustration {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 31vh;
    }

    .sctAbout__illustration__image {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 28vh;
        height: 63.45%;
        min-width: 50%;
        background-image: var(--_url);
        background-size: contain;
        background-repeat: no-repeat;
        /* background-color: rgba(var(--base-ntrl-900), 1); */
        border-radius: var(--border-radius-default);
        box-shadow: 0 0 8px rgba(var(--base-shade-max), .15);
        transform-style: preserve-3d;
    }

    .sctAbout__content h2 {
        text-align: center !important;
    }

    .sctAbout__illustration__image::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform:
            translate(-50%, -50%)
            translateZ(-1px);
        width: 105%;
        height: 105%;
        border: 2px solid rgba(var(--base-primary), 1);
        border-radius: var(--border-radius-default);
    }

    .sctAbout__illustration__image button {
        height: 100%;
        width: 50px;
        border: none;
        text-align: center;
        color: rgba(var(--base-shade-max), 1);
        font-size: var(--paragraph-large-fx);
        cursor: pointer;
    }

    /* Features section */
    .sctFeatures {
        min-height: 35vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: calc(var(--gap-default)*3);
        margin: 2rem 0 0;
        
    }

    .sctFeatures__features {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .svgStyle{
        width: 100%;
        height: 100%;
    }
    
    .sctFeatures__feature {
        max-width: 250px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 150px;
        gap: var(--gap-default);
        align-items: center;
        gap: 30px;
    }

    .sctFeatures__feature p {
        display: flex;
        align-items: center;
        font: var(--font-paragraph);
    }

    /* Areas section */
    .sctArea {
        --_sct-height: 90vh;
    }

    .sctAreaContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 400px 1fr;
    }

    .sctAreaContainer > :first-child {
        order:1
    }

    div .sctArea__content> :last-child {
        margin: 0 0 1rem;
    }

    /* Business section */
    .sctBusiness {
        display: flex;
        flex-direction: column;
        gap: calc(var(--gap-default)*5);
    }

    .sctBusiness__content {
        width: 90%;
        margin-top:0rem;
        margin-bottom: 2rem;
    }

    .sctBusiness hr {
        color: rgba(var(--base-accent), 1);
    }

    .sctBusiness__title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: calc(var(--gap-default)*3);
        margin-top: 2rem;
        margin-left: -2rem;
    }

    .sctBusiness__title hr {
        width: 100%;
        height: 1px;
    }

    .sctBusiness__title h2 {
        font: var(--font-heading);
        white-space: nowrap;
    }

    .sctBusiness__title h2 span {
        color: rgba(var(--base-accent), 1);
    }

    .sctBusiness .sct { --_sct-height: 60vh; }

    .sctBusiness__illustration {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr; */
        gap: 16px;
    }

    .sctBusiness__illustration__card__top {
        flex-direction: column;
    }

    .sctBusiness__illustration__card {
        display: flex;
        align-items: center;
        padding: var(--padding-spacing);
        border-radius: var(--border-radius-default);
        border:1px solid rgba(var(--base-accent), 1);
        width: 100%;
    }

    .sctBusiness__illustration__card span {
        display: block;
        min-width: 100px;
        min-height: 100px;
        height: 100px;
    }

    .sctBusiness__illustration__card p {
        font-size: var(--paragraph-large-fx);
    }

    /* Spotsat section */
    .sctSpotsat {
        --_sct-height: 40vh;
        display: flex;
        flex-direction: column;
    }

    .sctSpotsat >:first-child {
        order: 2;
    }

    .sctSpotsat__illustration {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imageSpotsat {
        width: 80%;
    }

    /* Register modal */
    dialog button {
        min-width: 80% !important; 
    }

    dialog *:has(> input) {
        max-width: 80% !important;
    }

    dialog:has(input) > p {
        font: var(--font-paragraph);
    }

    .modalOpenLogi, .modalRegister {
        text-align: center;
        font-size: var(--font-paragraph);
    }

    .paragraphPadding {
        padding: var(--padding-spacing);
    }

    .paragraphPaddingTop {
        padding-top:1rem
    }

    .paragraphPaddingBottom {
        padding-bottom:1rem
    }

    .modalParagraph {
        width: 80% !important;
        text-align: right;
    }

    .paragraphCenter {
        text-align: center;
    }

    .paragraphLeft {
        text-align: left;
    }

    .paragraphError {
        width: 80% !important;
        text-align: left;
        padding-bottom:1rem
    }

    .modalUnderline {
        text-decoration: underline;
        cursor: pointer;
    }

    .sctAreaMargin {
        padding: 0rem 0;
        gap: var(--gap-default);
        display: flex;
        flex-direction: column;
    }

    .marginVertical {
        margin: 1.2rem 0;
    }

    .marginRight{
        margin-right: 3rem;
    }

    /* Banner Imagem */
    .divBanner .webBanner {
        display: none;
    }

    .divBanner .tabletBanner {
        display: none;
    }
    
    .divBanner .mobileBanner {
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}