.modal {
    width: 350px;
    height: 450px;
    /*height: min-content;*/
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: var(--border-radius-default);
    background-color: rgba(var(--base-shade-min), 1);
    padding: var(--padding-spacing);
    padding-bottom: 30px;
    overflow: visible;
}

.modal::backdrop {
    background-color: rgba(0, 0, 0, .25);
}

.close {
    position: absolute;
    top: 5%;
    right: 7%;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold;
}

.modalBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap-large);
    width: 100%;
    min-height: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    gap: var(--gap-default);
    width: 100%;
    padding: var(--padding-spacing);
}

.header h3 {
    font-size: var(--heading-small-fx);
    text-align: center;
    margin-bottom: -0.5rem;
}

.header p {
    text-align: left;
}