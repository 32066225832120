.heading {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleNav {
    font:var(--font-heading)
}

.containerDiv {
    min-height: 90vh;
    padding: 10px 50px 0;

}

.containerForm{
    width: 100%;
}

.title {
    margin-top: 2rem;
    font:var(--font-heading)
}

.password {
    font-weight: bold;
    margin: 2rem 0 1rem 0;
    font: var(--font-paragraph);
    font-weight: bold;
}

.container{
    display: flex;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-default);
    width: 45%;
    position: relative;
}

.inputGroup input {
    width: 90%;
    padding: var(--padding-spacing);
    border-radius: var(--border-radius-default);
    border: #B1B1B1 solid 2px;
}

.divLabel {
    display: flex;
    align-items: center;
}

.iconHelp {
    width: 20px;
    height: 20px;
    margin-left: 1rem;
    cursor: pointer;
    color: #3F5855;
}

.divPopupHover {
    position: relative;
    top:-170px;
    left: 10px;
}

.popupHover {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width:200px;
    height: 150px;
    background-color: #3F585520;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
}

.modalParagraphTitle {
    font-size: var(--paragraph-small-fx);
    font-weight: 500;
}

.modalParagraph {
    font-size: var(--paragraph-small-fx);
    list-style-type: none;
}

.passwordInvalid {
    color: rgba(var(--base-error), 1);
    font-size: var(--paragraph-small-fx);
}

.textLabel {
    font: var(--font-paragraph)
}

.containerButton {
    display:flex; 
    justify-content: flex-end;
    gap: var(--padding-spacing);
    margin: 3% 3.4% 0 0;
    align-items: center;
    gap: calc(var(--gap-large)*4);
}

.containerValidation {
    position:relative;
    width:250px;
}

.cardValidation {
    position: absolute;
    margin-top: 10px;
}

.passwordValid p {
    font-size: 14px;
    line-height: 20px;
}

.divIconEye {
    position: relative;
    display: flex;
    align-items: center;
    width: 90%;
}

.divIconEye > :first-child {
    width:100%;
}

/* Mostrar senha? */
.divEyesPassword {
    position: absolute;
    left: 90%;
    align-items: center;
}

.iconEye {
    display: flex;
    align-items: center;
    color: #121212;
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .containerDiv {
        min-height: 90vh;
        padding: 0 0px;
    }
    .password {
        margin: 1rem 0 0 0;
    }

    .inputGroup {
        width: 70%;
        margin-top: 15px;
    }
    
    .containerButton {
        align-items: center;
    }
}

@media only screen and (max-width: 440px) {
    .containerDiv {
        min-height: 90vh;
        padding: 0 0px;
    }
    .password {
        margin: 1rem 0 0 0;
    }

    .container {
        flex-direction: column;
    }

    .divPopupHover {
        left: -80px;
    }
    
    .inputGroup {
        width: 110%;
        margin-top: 15px;
    }
    
    .containerButton {
        flex-direction: column;
        align-items: center;
        margin: 5% 0 6rem 0;
    }

    .containerButton > :first-child {
        order: 2;
        width: 175px;
        margin-top: -40px;
    }

    .containerButton > :last-child {
        order: 1;
        margin-top: 10px;
    }
}
