.formContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.companyForm {
    width: 80%;
}

.divNavigation{
    padding: 0 5%;
}

.pageTitle {
    font: var(--font-heading);
    margin-bottom: 30px;
}

.loginTitle {
    font-size: var(--paragraph-large-fx);
    margin-bottom: 1rem;
}

.loginSubtitle {
    font: var(--font-paragraph);
    color: rgba(var(--base-ntrl-400));
}

.row1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 30px;
}

.row10 {
    display: flex;
    margin-bottom: 30px;
    justify-content: flex-start;
    gap: 9%;
    max-width: 64%;
    margin-top: 1rem;
}

.infoTop {
    display: flex;
    gap: 6%;
}

.inputItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--gap-default);
    position: relative;
}

.error {
    color: red;
    font-size: 9px;
}

.errorPasswordContainer {
    display: flex;
    /* position: absolute; */
    top: 85%;
}

.errorNumberContainer {
    display: flex;
    position: absolute;
    top: 85%;
}

.errorNumber {
    color: red;
    font-size: 9px;
}

.infoBottom {
    display: flex;
    width: 65%;
    gap: 9%;
}

.contactInput {
    width: 100%;
    display: flex;
    gap: var(--gap-default);
    align-items: center;
    position: relative;
    
}

.contactInput >:first-child {
    width: 30%;
    display: flex;
    margin-bottom: 1.3rem;
}

.row2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}

.addressTop, .addressBottom {
    display: flex;
    gap: 6%;
}

.toTerms {
    text-decoration: underline;
    cursor: pointer;
    color:#551A8B
}

.marginLeft {
    margin-left: 10px;
}


.buttons {
    display: flex;
    justify-content: flex-end;
    height: 80px;
    align-items: flex-end;
    gap: var(--gap-default);
}

.cardModal > :first-child {
    height: 400px;
}

.cardModal p {
    text-align: justify;
}

.divModal {
    width: 80%;
}

.footer {
    padding:4rem 0 2rem; 
    width:90%;
    margin:0 auto
}

@media only screen and (max-width: 900px) {
    .formContainer {
        overflow: hidden;
    }

    .infoTop {
        flex-wrap: wrap;
        margin-bottom: 35px;
    }

    .infoBottom {
        flex-wrap: wrap;
        width: 100%;
    }

    .inputGroup {
        width: 100%;
    }
    
    .inputGroup label {
        margin-top: 5px;
    }

    .row2 {
        flex-wrap: wrap;
        gap: var(--gap-default);
    }

    .errorNumberContainer {
        display: flex;
        position: absolute;
        top: 110%;
    }

    .row3 {
        gap: var(--gap-default);
    }

    .addressTop{
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    .addressTop, .addressBottom {
        flex-wrap: wrap;
    }

    .consentInput {
        margin: 3rem 0;
    }

    .consentInput input[type="checkbox"] {
        margin-right: 0.5rem;
    }
}

@media only screen and (max-width: 440px) {
    .row1 {
        flex-direction: column;
        gap: var(--gap-default);
    }

    .inputGroup input {
        width: 100%;
    }

    .row2 {
        flex-direction: column;
        gap: var(--gap-default);
    }

    .row3 {
        gap: var(--gap-default);
    }

    .addressTop{
        flex-wrap: wrap;
        margin-bottom: 0px;
    }

    .addressTop, .addressBottom {
        flex-direction: column;
        gap: var(--gap-default);
    }

    .buttons {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .buttons > :first-child {
        order: 2;
    }

    .buttons > :last-child {
        order: 1;
    }

    .consentInput {
        margin: 3rem 0;
    }

    .consentInput input[type="checkbox"] {
        margin-right: 0.5rem;
    }
}
