.sidebar {
    display: flex;
    flex-direction: column;
    gap: var(--gap-large);
    position: fixed;
    min-height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(var(--base-shade-min), 1);
    padding: var(--padding-spacing);
    z-index: 1;
    border-right: 1px solid rgba(var(--base-ntrl-300), 1);
}

.sidebar.collapsed {
    width: 10px;
}

.sidebar.expanded {
    width: 300px;
}

.toggleVisibilityButton {
    --_size: 30px;
    width: var(--_size);
    height: var(--_size);
    position: absolute;
    top: calc(0% + 1rem);
    left: calc(100% - (var(--_size)/2));
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--base-primary), 1);
    font-size: var(--paragraph-large-fx);
    color: rgba(var(--base-shade-min), 1);
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    z-index: 1;
    transition: filter .1s linear;
    box-shadow: 2px 0 4px rgba(var(--base-shade-max), .25);
}

.toggleVisibilityButton:hover {
    filter: brightness(1.2);
}

hr {
    color: rgba(var(--base-ntrl-300), 1);
}

.titleGroup {
    font-Size: var(--paragraph-small-fx); 
    font-Weight: normal;
}

.titleGroup.titleCollapsed {
    visibility: hidden;
}

.infoGroup {
    display:flex;
    flex-direction: column
}
