.sidebarItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-decoration: none;
    border-radius: var(--border-radius-default);
    background-color: rgba(var(--base-shade-min), 1);
    transition: filter .1s linear;
}

.sidebarItem:hover {
    filter: brightness(.9);
}

.sidebarItem.collapsed {
    width: 40px;
    height: 40px;
}

.sidebarItem.expanded {
    min-width: 200px;
    max-width: 300px;
    font: var(--font-paragraph);
    padding: var(--padding-items);
}

.icon {
    color: rgba(var(--base-primary), 1);
}

.collapsed .icon {
    font: var(--font-paragraph);
    margin: 0 auto;
}

.placeholder {
    color: rgba(var(--base-shade-max), 1);
}