.statusTag {
    --_iconPos: -1rem;
    position: relative;
    display: inline-block;
    width: 100px;
    margin-left: calc(var(--_iconPos) * -1);
}

.statusTag::before {
    --_iconSize: 12px;
    content: "";
    width: var(--_iconSize);
    height: var(--_iconSize);
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-50%, -50%);
    border-radius: var(--border-radius-full);
}

.statusTag[data-status="Aprovado"]::before {
    background-color: rgba(var(--base-success), 1)
}

.statusTag[data-status="Pendente"]::before {
    background-color: rgba(var(--base-warning), 1)
}

.statusTag[data-status="Cancelado"]::before {
    background-color: rgba(var(--base-error), 1);
}