.container {
    min-height: 90vh;
    padding: .6rem 3rem 0;
}

.headingNav {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleNav {
    font:var(--font-heading)
}

.containerInfo {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
}

.cardInfo {
    line-height: 30px;
}

.titleInfo, .titleMap{
    font: var(--font-paragraph);
    font-weight: bold;
}

.paragraphInfo {
    font: var(--font-paragraph);
}

.paragraphData {
    font: var(--font-paragraph);
    font-size: var(--paragraph-small-fx);
    font-style: italic;
    padding: 2rem 0;
}

.containerTitleMap {
    font: var(--font-paragraph);
    padding: 1rem 0;
    display: flex;
    align-items: center;
}

.paragraphSubtitle {
    font:var(--font-paragraph);
    padding-right: 1rem;
}

.titleAreaGreen, .titleAreaRed {
   margin-right: 1rem;
   font:var(--font-paragraph);
   display: flex;
}

.titleAreaGreen::before {
    background-color:#46625F;
    padding: 13px;
    content: "";
    font-size: 0;
    border-radius:6px;
    margin-right: 5px;
}

.titleAreaRed::before {
    background-color:#DC3545;
    padding: 13px;
    content: "";
    font-size: 0;
    border-radius:6px;
    margin-right: 5px;
}

.mapContainer {
    margin: 0 auto;
    width: 80%;
    min-height: 80vh;
}

.paddingMap {
    margin-bottom: 2rem;
}

.titleMapDeforested {
    font: var(--font-paragraph);
    font-weight: bold;
    margin-top: 3rem;
}

.containerButton {
    margin:2rem 0 4rem 0; 
    display:flex;
    justify-content:center
}

.map { 
    height: 60vh;
}

/* Loading */
.containerImageLoading {
	width: 100%;
	min-height: 100%;
	position: absolute;
	z-index: 1;
	backdrop-filter: blur(1px);
	background: rgba(255, 255, 255, 0.3);
}

.divImageLoading {
	position: absolute;
	right: 50%;
	top: 50%;
	z-index: 1;
}

.imageLoading{
	width: 100px;
	height: 100px;
	color:#273F29
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.imageLoading {
    display: inline-block;
    animation: rotate 1.5s linear infinite;
}

@media only screen and (max-width: 900px) {
    .container {
        padding: .6rem 1rem 0;
    }
    
    .containerInfo {
        /* flex-wrap: wrap; */
        flex-direction: column;
    }
    
    .cardInfo {
        padding-bottom: 1rem;
    }

    .paragraphData {
        padding: 2rem 0 3rem;
    }
    
    .imageMap {
        margin-bottom: 3rem;
    }
    
    .paddingMap {
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width: 440px) {
    .container {
        padding:.5rem
    }
    
    .containerInfo {
        padding-top: 1.5rem;
    }

    .paragraphData {
        font: var(--font-paragraph);
        font-size: var(--paragraph-small-fx);
        font-style: italic;
        padding: 1.5rem 0 2.5rem;
    }
    
    .containerTitleMap {
        font: var(--font-paragraph);
        padding: 1rem 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }
    
    .paragraphSubtitle {
        font:var(--font-paragraph);
        padding-right: 10rem;
        margin-top: -10px;
    }

    .titleAreaGreen::before,
    .titleAreaRed::before {
        padding: 10px;
    }

    .containerButton {
        margin:20px 0 40px; 
        padding-bottom: 60px;
    }
}
