.inputGroup {
    display: flex;
    flex-direction: column;
    gap: var(--gap-default);
    width: 100%;
}

.inputGroup input {
    padding: var(--padding-items);
    border: #B1B1B1 solid 2px;
    border-radius: var(--border-radius-default);
    width: 100%;
    font: var(--font-paragraph);
    align-items: center;
}

.divLabel {
    display: flex;
    align-items: center;
}

.iconHelp {
    width: 20px;
    height: 20px;
    margin-left: 1rem;
    cursor: pointer;
    color: #3F5855;
}

.divPopupHover {
    position: relative;
    top: -170px;
    left: 10px;
}

.popupHover {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    height: 150px;
    background-color: rgba(var(--base-shade-min), 1);
    border-radius: 5px;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
}

.modalParagraphTitle {
    font-size: var(--paragraph-small-fx);
    font-weight: 500;
}

.modalParagraph {
    font-size: var(--paragraph-small-fx);
    list-style-type: none;
}

/* Visualizar senha */
.containerInput {
    display: flex;
    align-items: center;
    position: relative;
}

.divEyesPassword {
    position: absolute;
    left: 85%;
    align-items: center;
}

.iconEye {
    display: flex;
    align-items: center;
    color: #121212;
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .inputGroup {
        width: 100%;
    }

    .inputGroup label {
        margin-top: 5px;
    }
}

@media only screen and (max-width: 440px) {
    .inputGroup input {
        width: 100%;
    }
}