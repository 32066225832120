.layout {
    width: inherit;
    min-height: inherit;
}

.layout > main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: rgba(var(--base-ntrl-100), 1);
    padding-inline: 80px 2rem;
}