.profile {
    display: flex;
    gap: var(--gap-default);
}

.infoLogged >:first-child {
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
}

.info > :first-child {
    text-decoration: underline;
    cursor: pointer;
}

.icon {
    --_size: 40px;
    width: var(--_size);
    height: var(--_size);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--base-shade-min), 1);
    font: var(--font-paragraph);
    border-radius: var(--border-radius-default);
    transition: filter .1s linear;
    cursor: pointer;
}

.icon:hover {
    filter: brightness(.9);
}