.heading {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wizard {
    display: grid;
    grid-template-columns: auto 300px;
    grid-template-rows: min-content minmax(calc(60vh - var(--gap-default)), min-content) min-content;
    row-gap: calc(var(--gap-default) * 5);
    column-gap: calc(var(--gap-default) * 3);
}

.bar {
    --_progress-color: rgba(var(--base-primary), 1);
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
    gap: var(--gap-default);
}

.progressBar {
    width: 100%;
    height: 8px;
    position: relative;
}

.progressBar>* {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.progressBackground {
    width: 100%;
    height: 8px;
    background-color: rgba(var(--base-ntrl-300), 1);
    border-radius: 100px;
}

.progressBarValue {
    width: var(--_width);
    height: 8px;
    background-color: rgba(var(--base-primary), 1);
    border-radius: var(--border-radius-default);
    transition: width .5s ease-in-out;
}

.stepNames {
    display: flex;
    justify-content: space-evenly;
}

.stepNames p {
    font: var(--font-paragraph);
    transition: all .1s linear;
}

.stepNames p[data-currentstep="true"] {
    font-weight: bold;
}

.stepNames p::before {
    --_stepName-color: rgba(var(--base-ntrl-300), 1);
    content: "";
    position: relative;
    left: 50%;
    bottom: calc(-132% + (var(--gap-default) / 4));
    transform: translate(-50%, -50%);
    display: block;
    width: 20px;
    height: 20px;
    background-color: var(--_stepName-color);
    border-radius: var(--border-radius-full);
    transition: all .1s linear .2s;
    z-index: 1;
}

.stepNames p[data-active="true"]::before {
    --_stepName-color: var(--_progress-color);
}

.steps {
    display: flex;
    overflow: hidden;
}

.step {
    min-width: 100%;
}

.info {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap-default) * 2);
}

.info p {
    font: var(--font-paragraph);
    font-weight: bold;
}

.generalInfo,
.addressInfo,
.cardInfo {
    display: flex;
    gap: var(--gap-default);
    flex-wrap: wrap;
    margin-inline-end: 1rem;
}

.generalInfo>*,
.addressInfo>*,
.cardInfo>* {
    max-width: 275px;
}

.date {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.date span {
    color: red;
}

.paymentMethods {
    flex-wrap: wrap;
}

.paymentMethods,
.paymentMethods label {
    display: flex;
    align-items: center;
    gap: calc(var(--gap-default) * 2);
}

.paymentMethods label {
    min-width: 200px;
    width: 300px;
    padding: var(--padding-spacing);
    border: 2px solid rgba(var(--base-ntrl-300), 1);
    border-radius: var(--border-radius-default);
    cursor: pointer;
}

.paymentMethods label:has(> input:checked) {
    border-color: rgba(var(--base-primary), 1);
}

.paymentMethods label input:checked {
    accent-color: rgba(var(--base-primary), 1);
}

.paymentMethods label input[disabled="true"] {
    pointer-events: none;
    opacity: .3;
}

.disabledCard {
    pointer-events: none;
    opacity: .3;
}

.paymentMethods label p {
    flex: 1;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardPayment {
    margin-top: calc(var(--gap-default) * 2);
    display: flex;
    transition: opacity .3s ease-in-out;
}

.cardPayment[data-selected="true"] {
    opacity: .3;
}

.cardPayment[data-selected="true"] input {
    pointer-events: none;
}

.cardSvg {
    width: 100%;
    padding-left: 20px;
    max-width: 300px;
}

.buttons {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: calc(var(--gap-default) * 5);
}

.cart {
    grid-column: 2 / 3;
}

.cartBody {
    position: sticky;
    top: var(--gap-default);
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap-default) * 2);
    border: 1px solid rgba(var(--base-ntrl-300), 1);
    border-radius: var(--border-radius-default);
    padding: var(--padding-spacing);
    background-color: rgba(var(--base-shade-min), 1);
}

.cartTitle {
    display: flex;
    gap: var(--gap-default);
    align-items: center;
}

.cartItems ul {
    list-style: none;
}

.cartItems ul li,
.cartTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: var(--font-paragraph);
}

.pixPayment,
.paymentInfoContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: var(--font-paragraph);
}

@media only screen and (max-width: 1025px) {
    .cardSvg {
        display: none;
    }
}

@media only screen and (max-width: 769px) {
    .bar {
        display: none;
    }

    .wizard {
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: 711px) {
    .cart {
        grid-column: 1 / span 2;
        grid-row: 1 / 2;
        margin-top: 25px;
    }

    .steps {
        grid-column: 1 / span 2;
        grid-row: 2 / 3;
    }

    .buttons {
        grid-column: 1 / span 2;
        grid-row: 3 / 4;
        flex-direction: row;
    }

    .cardInfo>* {
        max-width: none;
    }
}