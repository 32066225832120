.heading {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.listagem {
    flex: 1;
}

.table__filters {
    display: flex;
    gap: var(--gap-default);
    margin-bottom: 1rem;
}

.table__filters label {
    display: flex;
    flex-direction: column;
    font-size: var(--paragraph-small-fx);
    gap: var(--gap-default);
}

.table__filters :is(input, select) {
    min-width: 150px;
    width: 250px;
    height: 100%;
    padding: .3rem .6rem;
    font: var(--font-paragraph);
}

.table__wrapper {
    width: 100%;
    /* border: 2px solid rgba(var(--base-shade-min), 1); */
    border-radius: var(--border-radius-default);
    overflow-y: hidden;
    overflow-x: visible;
    margin: 2rem 0rem;
}

.table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
}

.col__expand { width: 50px; }
.col__id { width: 40px; }
.col__name { width: auto; }
.col__status { width: 300px; }
.col__actions { width: 50px; }

.table__head th {
    padding: var(--padding-spacing);
    font-size: var(--paragraph-small-fx);
    text-transform: uppercase;
    font-weight: 600;
    background-color: rgba(var(--base-primary), 1);
    color: rgba(var(--base-shade-min), 1);
}

.table__body tr {
    margin-inline: 20px;
}

.table__body td {
    padding: var(--padding-spacing);
    background-color: rgba(var(--base-shade-min), 1);
}

.table__body td:is(:nth-child(2), :nth-child(4)) {
    text-align: center;
}

.table__row td > svg {
    display: flex;
    justify-content: center;
    align-items: center;
    font: var(--font-paragraph);
    color: rgba(var(--base-ntrl-400), 1);
    border: 1px solid rgba(var(--base-ntrl-300), 1);
    border-radius: var(--border-radius-default);
    cursor: pointer;
    transition: background-color .1s linear;
}

.table__row td > svg:active {
    filter: brightness(.7);
}

.table__row td > svg[data-selected="true"] {
    color: rgba(var(--base-shade-min), 1);
    background-color: rgba(var(--base-primary), 1);
    border: 1px solid rgba(var(--base-primary), 1);
    rotate: 180deg;
}

.table__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap-default);
    padding: var(--padding-items);
}

.table__pagination button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap-default);
    font-size: var(--paragraph-small-fx);
    padding: var(--padding-items);
    border: 2px solid rgba(var(--base-primary), 1);
    border-radius: var(--border-radius-default);
    background-color: rgba(var(--base-shade-min), 1);
    cursor: pointer;
}

.dropdown {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    font-size: var(--paragraph-large-fx);
    color: rgba(var(--base-primary), 1);
    cursor: pointer;
}

.dropdown__links {
    display: none;
    position: absolute;
    left: -50%;
    bottom: -830%;
    transform: translate(-50%, -50%);
    list-style: none;
    font-size: var(--paragraph-small-fx);
    text-align: left;
    background-color: rgba(var(--base-ntrl-100), 1);
    border-radius: var(--border-radius-default);
    z-index: 1;
}

.dropdown__links::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 72%;
    transform: 
        translate(-50%, -50%)
        rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: rgba(var(--base-ntrl-100), 1);
    z-index: -1;
}

.dropdown__links li {
    color: rgba(var(--base-shade-max), 1);
    padding: var(--padding-items);
}

.dropdown__links li:hover {
    background-color: rgba(var(--base-ntrl-300), .3);
}

.dropdown__links[data-open="true"] {
    display: block;
}

.table__row__hidden {
    display: none;
    width: 100%;
    padding: 0;
}

.table__row__hidden td {
    border-bottom: 1px solid rgba(var(--base-ntrl-300), 1);
}

.row__hidden__content {
    display: flex;
    flex-direction: column;
    gap: var(--gap-default);
    width: 100%;
}

.row__hidden__content h3 {
    color: rgba(var(--base-primary), 1);
}

:is(
    .hidden__content__user__info,
    .hidden__content__contract__info,
    .contract__general__info
) {
    display: flex;
}

.contract__general__info {
    gap: calc(var(--gap-default) * 3);
}

:is(
    .user__address__info,
    .user__general__info,
) {
    flex: 1;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: var(--gap-default);
}

:is(
    .user__address__info,
    .user__general__info,
    .contract__general__info
) p {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-basis: 200px;
    font: var(--font-paragraph);
}

:is(
    .user__address__info,
    .user__general__info,
    .contract__general__info
) p :first-child {
    font-weight: bold;
    color: rgba(var(--base-primary), 1);
}

.history {
    position: fixed;
    top: 0;
    right: -100%;
    width: 500px;
    max-width: 100%;
    height: 100vh;
    padding: var(--padding-spacing);
    background-color: rgba(var(--base-shade-min), 1);
    box-shadow: -2px 0 4px rgba(var(--base-shade-max), .25);
    overflow-y: scroll;
    transition: right .3s ease-in-out;
}

.history::-webkit-scrollbar {
    display: none;
}

.history__title {
    display: flex;
    align-items: center;
    gap: var(--gap-default);
}

.history__title svg {
    cursor: pointer;
    font-size: var(--paragraph-large-fx);
}

.history__title h3 {
    font: var(--font-heading);
}

.history__data {
    list-style: none;
    border-left: 2px solid rgba(var(--base-primary), 1);
    padding: var(--padding-items);
    margin: 0 2.2rem;
}

.history__data li {
    position: relative;
    margin: 1rem;
}

.history__data li::before {
    content: "";
    position: absolute;
    top: 25%;
    left: -10%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: var(--border-radius-full);
}

.history__data li p:last-child {
    font-size: var(--paragraph-small-fx);
}

.history__data li[data-type="Success"]::before {
    background-color: rgba(var(--base-primary), 1);
}

.history__data li[data-type="Failed"]::before {
    background-color: rgba(var(--base-error), 1);
}

.history__data li[data-type="Success"] {
    color: rgba(var(--base-shade-max), 1);
}

.history__data li[data-type="Failed"] {
    color: rgba(var(--base-error), 1);
}

.history__user__name {
    margin: .5rem 1.8rem;
    display: flex;
    gap: var(--gap-default);
}

.history__user__name {
    font-weight: bold;
}

.history__user__name p {
    font-weight: normal;
}

@media only screen and (max-width: 610px) {
    .table__filters {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 2rem;
        gap: var(--gap-default);
        margin-bottom: 1rem;
    }

    .table__filters :is(input, select) {
        min-width: 150px;
        width: 100%;
        height: 100%;
        padding: .3rem .6rem;
        font: var(--font-paragraph);
    }
}