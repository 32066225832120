.breadcrumb {
    list-style: none;
    display: flex;
    align-items: center;
    gap: calc(var(--gap-default)/2);
}

.breadcrumb li:first-child {
    font-weight: bold;
}

@media only screen and (max-width: 900px) {
    .breadcrumb li {
        font-size: 12px;
    }
}
@media only screen and (max-width: 440px) {}