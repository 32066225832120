.paragraphPadding {
    padding: var(--padding-spacing);
}

.container > :first-child {
    width: 350px;
    height: 400px;
}

.modalParagraph {
    width: 80% !important;
    text-align: center;
    margin: 20px 0 10px;    
}

@media only screen and (max-width: 900px) {

}

@media only screen and (max-width: 440px) {
    .container > :first-child {
        width: 300px;
        height: 350px;
    }
}