.mapContainer {
    max-width: 900px;
    min-width: 800px;
    min-height: 80vh;
    margin: 0 auto;
}

@media only screen and (max-width: 440px) { 
    .mapContainer {
        max-width: 300px;
        min-width: 280px;
        min-height: 30vh;
        margin: 0 auto;
    }
}