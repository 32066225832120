.paragraphPadding {
    padding: var(--padding-spacing);
}

.paragraphPaddingTop {
    padding-top: 1rem
}

.paragraphPaddingBottom {
    padding-bottom: 1rem
}

.paddingParagraph {
    padding: 1rem 0
}

.modalParagraph {
    width: 80% !important;
    text-align: center;
}

.modalParagraph p {
    text-align: center;
}

.textPassword {
    margin-bottom: -1rem;
}

.paragraphCenter {
    text-align: center;
}

.paragraphLeft {
    text-align: left;
}

.modalUnderline {
    text-decoration: underline;
    cursor: pointer;
}

.email {
    text-decoration: underline;
}

.paragraphError {
    width: 80% !important;
    text-align: center;
    padding-bottom: 1rem
}

.cardLoginModal> :first-child {
    height: 480px;
}

.cardUnsubscribeModal> :first-child {
    width: 400px;
}

.cardSuccess>:first-child {
    width: 380px;
}

.containerButtonUnsubscribe {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.divButtonUnsubscribe {
    display: flex;
    justify-content: center;
}

.divButtonUnsubscribe> :first-child {
    width: 100px;
    height: 50px;
}

.divButtonUnsubscribe2> :first-child {
    width: 250px;
    height: 50px;
}

.containerButtonToMonitor {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}

.containerButtonToMonitor> :first-child,
.containerButtonToMonitor> :last-child {
    width: 100px;
    height: 50px;
}

.emailHint.invalid,
.passwordHint.invalid {
    color: red;
    text-align: left;
    font-size: var(--paragraph-small-fx);
}

.invalidNewPassword {
    margin: -20px 0 5px;
    color: red;
    text-align: left;
    font-size: var(--paragraph-small-fx);
    width: 80%;
    font-size: 11px;
}

.msgError {
    color: red;
    font-size: var(--paragraph-small-fx);
    margin: -0.5rem 0 0;
    display: flex;
    align-self: start;
    padding-left: 2rem;
}

/* Modal de login */

/* .containerInput {
    width: 80%; 
}
*/

.containerInput > :first-child {
    margin-top: 5px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-default);
    width:100%;
    position: relative;
    margin-top: 15px;
    margin-bottom: 10px;
}
    
.inputGroup input {
    width: 255px !important ;
    padding: 15px 20px;
    border-radius: var(--border-radius-default);
    border: #B1B1B1 solid 2px;
}

.divIconEye {
    position: relative;
    display: flex;
    align-items: center;
    width: 80%
}
/* 
.divIconEye > :first-child {
    width:100%;
} */

/* Mostrar senha? */
.divEyesPassword {
    position: absolute;
    top: 30% !important;
    right: 8% !important;
    align-items: center;
}

.divEyesPasswordRecover {
    position: absolute;
    right: 8%;
    align-items: center;
}

.iconEye {
    display: flex;
    align-items: center;
    color: #121212;
    cursor: pointer;
}

/* Modal - Inserir email de recuperação de senha */

.centerInput {
    background-color: blue;
    width: 100%
}

.centerInput > :first-child {
    /* background-color: yellow; */
    display: flex;
    width: 100%;
    align-items: center;
}

.centerInput div label {
    display: none;
}


/* Modal - Recuperação de senha */
.divInputRestorePassword > :first-child{
    width: 320px;
    margin-left: 10%;
    margin-bottom: 20px;
}

.divInputRestorePassword > :last-child{
    width: 320px;
    margin-left: 10%;
}