@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./variables.css";

* {
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

#root {
	width: 100%;
	min-height: 100vh;
}

.leaflet-control {
	z-index: 0 !important;
}
.leaflet-pane {
	z-index: 0 !important;
}
.leaflet-top,
.leaflet-bottom {
	z-index: 0 !important;
}
