/* Defaults */
.sct {
    width: 100%;
    --_sct-height: 100vh;
    min-height: var(--_sct-height);
    max-width: 90%;
    margin: 0 auto;
}

.sct__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-spacing);
    gap: var(--gap-default);
}

.sctPolicy__fake_list {
    list-style: none;
}


.sct__content h1 { font: var(--font-display); }
.sct__content > h2, .sct__content > h3 { font: var(--font-heading); margin: 3rem 0 0rem; }
.sct__content > p, .sct__content > li, .sct__content > ol  { font: var(--font-paragraph); } 
.sct__content > ol  { 
    padding-inline: 2rem;
} 

/* Hero section */
.sctPolicy { --_sct-height: 90vh; }

.sctPolicy__content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content auto;
}

.sctPolicy__help {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 900px) {
    .sct {
        margin: 20px auto 100px auto;
    }
}