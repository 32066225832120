.paragraphPadding {
	padding: var(--padding-spacing);
}

.paragraphPaddingTop {
	padding-top: 1rem;
}

.paragraphPaddingBottom {
	padding-bottom: 1rem;
}

.modalParagraph {
	width: 80% !important;
	text-align: right;
}

.paragraphCenter {
	width: 80% !important;
	text-align: center;
}

.paragraphLeft {
	text-align: left;
}

.modalUnderline {
	text-decoration: underline;
	cursor: pointer;
}

.paragraphError {
	width: 80% !important;
	text-align: center;
	padding-bottom: 1rem;
}

.cardUnsubscribeModal > :first-child {
	width: 400px;
}

.containerButtonUnsubscribe {
	display: flex;
	gap: 10px;
	margin-top: 20px;
}

.divButtonUnsubscribe {
	display: flex;
	justify-content: center;
}

.divButtonUnsubscribe > :first-child {
	width: 100px;
	height: 50px;
}

.divButtonUnsubscribe2 > :first-child {
	width: 250px;
	height: 50px;
}

.containerButtonToMonitor {
	display: flex;
	gap: 10px;
	margin-top: 20px;
	justify-content: center;
}

.containerButtonToMonitor > :first-child,
.containerButtonToMonitor > :last-child {
	width: 90px;
	height: 50px;
}

@media only screen and (max-width: 440px) {
	.cardUnsubscribeModal > :first-child {
		width: 350px;
	}

	.containerButtonUnsubscribe {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-top: 20px;
	}

	.divButtonUnsubscribe {
		display: flex;
		justify-content: center;
		order: 2;
	}

	.divButtonUnsubscribe {
		display: flex;
		justify-content: center;
		order: 1;
	}

	.divButtonUnsubscribe > :first-child {
		width: 250px;
		height: 50px;
	}

	.divButtonUnsubscribe2 > :first-child {
		width: 250px;
		height: 50px;
	}

	.containerButtonToMonitor {
		display: flex;
		gap: 10px;
		margin-top: 20px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.containerButtonToMonitor > :first-child {
		width: 200px;
		height: 50px;
		order: 2;
	}

	.containerButtonToMonitor > :last-child {
		width: 200px;
		height: 50px;
		order: 1;
	}
}
