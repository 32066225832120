.heading {
	height: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.sctMap {
	min-height: 90vh;
	display: grid;
	grid-template-columns: 60% auto;
	grid-template-rows: min-content auto;
	gap: var(--gap-default);
	margin-bottom: 100px;
}

.sctMap__title {
	grid-column: 1 / span 2;
	height: min-content;
}

.sctMap__title h2 {
	font: var(--font-heading);
}

.sctMap__title p {
	font: var(--font-paragraph);
}

.sctMap__map {
	border-radius: var(--border-radius-default);
}

.sctMap__controllers {
	display: flex;
	flex-direction: column;
	gap: var(--gap-default);
	padding-inline: var(--padding-spacing);
}

.periods__wrapper {
	display: flex;
	justify-content: stretch;
	flex-wrap: wrap;
	width: 30%;
	gap: var(--gap-default);
}

.periods__wrapper label {
	flex-grow: 1;
	min-width: 50px;
	display: flex;
	flex-direction: column;
	gap: var(--gap-default);
	font-size: var(--paragraph-small-fx);
}

.periods__wrapper label input {
	padding: var(--padding-items);
	border: 1px solid rgba(var(--base-ntrl-300), 1);
	border-radius: var(--border-radius-default);
	outline: none;
}

.checkbox {
	display: flex;
	gap: var(--gap-default);
	padding-block: 1rem;
}

/* Hover botão de dicas */
.divLabel {
	display: flex;
	align-items: center;
}

.iconHelp {
	width: 20px;
	height: 20px;
	margin-left: 1rem;
	cursor: pointer;
	color: #3F5855;
}

.divPopupHover {
	position: relative;
	top: -40px;
	left: 20px;
}

.popupHover {
	position: fixed;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 175px;
	height: 50px;
	background-color: rgba(var(--base-shade-min), 1);
	border-radius: 5px;
	box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.2);
	padding: 0.5rem;
}

.modalParagraphTitle {
	font-size: var(--paragraph-small-fx);
	font-weight: 500;
	margin-left: 10px;
}

.modalParagraph {
	font-size: var(--paragraph-small-fx);
	list-style-type: none;
}

/* Image Loading */

.containerImageLoading {
	width: 100%;
	min-height: 100%;
	position: absolute;
	z-index: 1;
	backdrop-filter: blur(1px);
	background: rgba(255, 255, 255, 0.3);
}

.divImageLoading {
	position: absolute;
	right: 50%;
	top: 50%;
	z-index: 1;
}

.imageLoading {
	width: 100px;
	height: 100px;
	color: #273F29
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.actionButtons {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 10px;
}

.imageLoading {
	display: inline-block;
	animation: rotate 1.5s linear infinite;
}

@media only screen and (max-width: 1300px) {
	.sctMap {
		grid-template-columns: auto;
		grid-template-rows: min-content 90vh min-content;
	}

	.sctMap__title {
		grid-column: auto;
		margin-bottom: 2rem;
	}

	.areas__wrapper {
		justify-content: flex-start;
	}

	.sctMap__title h2 {
		margin-bottom: 2rem;
	}
}

@media only screen and (max-width: 900px) {
	.heading {
		padding-top: 1rem;
		margin-bottom: 2rem;
	}

	.areas__wrapper {
		width: 100%;
		display: flex;
		gap: var(--gap-default);
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		margin: 2rem auto;
	}

	.areas__button {
		min-width: 50px;
		width: 100%;
		height: 90px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: var(--gap-default);
		background-color: transparent;
		border: 1px solid rgba(var(--base-ntrl-300), 1);
		border-radius: var(--border-radius-default);
		color: rgba(var(--base-ntrl-300), 1);
		cursor: pointer;
		transition: filter 0.2s linear;
	}

	.sctMap {
		grid-template-rows: min-content 35vh min-content;
	}

	.sctMap__controllers {
		margin-top: 2rem;
	}

	.socialContainer {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 1rem 0;
	}
}