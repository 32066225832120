.navigation {
    display: flex;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    padding: 20px 6%;
    justify-content: space-between;
}

.link {
    text-decoration: none;
    font: var(--heading-large-fx);
    color: var(--base-ntrl-900);
    cursor: pointer;
}

@media only screen and (min-width: 441px) and (max-width: 720px) {
    .navigation {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8rem;
        padding: 0px 65px;
    }

    .link {
        text-decoration: none;
        font: var(--heading-large-fx);
        color: var(--base-ntrl-900);
        cursor: pointer;
    }
}

@media only screen and (max-width: 440px) {
    .navigation {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6rem;
        padding: 0px 40px;
    }

    .link {
        text-decoration: none;
        font: var(--heading-large-fx);
        color: var(--base-ntrl-900);
        cursor: pointer;
    }
}