:root {
    /* 
        Colors
    */
    --base-ntrl-100: 245, 245, 245;
    --base-ntrl-300: 209, 209, 209;
    --base-ntrl-400: 177, 177, 177;
    --base-ntrl-900: 7, 7, 7;

    --base-primary: 70, 98, 95;
    --base-secondary: 201, 244, 201;
    --base-accent: 142, 151, 204;
    --base-success: 126, 250, 138;
    --base-warning: 240, 250, 126;
    --base-error: 254, 93, 109;

    --base-shade-max: 0, 0, 0;
    --base-shade-min: 255, 255, 255;

    /* 
        Fonts
        Scale: 1.200 - Minor Third
    */
    --paragraph-small-fx: 0.833rem;
    --paragraph-regular-fx: 1.2vw;
    --paragraph-large-fx: 1.2rem;
    --font-paragraph:
        normal normal
        clamp(var(--paragraph-small-fx), var(--paragraph-regular-fx), var(--paragraph-large-fx)) / 150%
        'Inter', sans-serif;

    --heading-small-fx: 1.44rem;
    --heading-regular-fx: 2vw;
    --heading-large-fx: 2.074rem;
    --font-heading:
        normal 700
        clamp(var(--heading-small-fx), var(--heading-regular-fx), var(--heading-large-fx)) / 150%
        'Geologica', sans-serif;

    --display-small-fx: 2.448rem;
    --display-regular-fx: 3vw;
    --display-large-fx: 3.583rem;
    --font-display:
        normal 700
        clamp(var(--display-small-fx), var(--display-regular-fx), var(--display-large-fx)) / 150%
        'Geologica', sans-serif;

    /* 
        Paddings, margins, gaps and borders
    */
    --padding-spacing: 1rem 1rem;
    --padding-items: 0.5rem 1rem;

    --gap-default: 0.5rem;
    --gap-large: 1rem;

    --border-radius-default: 4px;
    --border-radius-full: 100%;
}